export * from './example-slider';
export * from './news-list';
export * from './news-load';
export * from './system-components-tabs';
export * from './news-item-slider';
export * from './product-slider';
export * from './example-filter';
export * from './example-popup-slider';
export * from './projects-filter';
export * from './system-accordion';
export * from './second-info-block-slider';
