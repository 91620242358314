import SwiperCore, { Keyboard, Navigation, Thumbs } from 'swiper';
import Swiper from 'swiper';

export function examplePopupSlider() {
  const $slider = $('.example-popup-slider');
  if (!$slider || $slider.length === 0) return;

  SwiperCore.use([Navigation, Keyboard, Thumbs]);

  document.querySelectorAll('.example-popup-slider').forEach((el) => {
    const thumb = new Swiper(el.nextElementSibling, {
      slidesPerView: 4,
      navigation: {
        nextEl: '.example-popup-slider-thumb-next',
        prevEl: '.example-popup-slider-thumb-prev',
      },
      spaceBetween: 25,
      watchSlidesProgress: true,
      observer: true,
      observeParents: true,
      breakpoints: {
        0: {
          slidesPerView: 3,
          spaceBetween: 10,
        },
        768: {
          slidesPerView: 4,
          spaceBetween: 25,
        },
      },
    });

    const swiper = new Swiper(el, {
      slidesPerView: 1,
      thumbs: {
        swiper: thumb,
      },
      observer: true,
      observeParents: true,
      allowTouchMove: false,
    });
  });
}
