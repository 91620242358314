import { isTablet } from '../modules/functions';

export function navigation() {
  const $navigation = $('.header-navigation');
  const $footerNavigation = $('.footer-navigation');
  let timeout;

  $navigation.find('.header-has-sub-navigation').on('mouseenter', (e) => {
    if (isTablet()) return;
    $(e.currentTarget).addClass('show');
    clearTimeout(timeout);
  });

  $navigation.find('.header-has-sub-navigation').on('mouseleave', (e) => {
    if (isTablet()) return;

    const $t = $(e.currentTarget);

    $t.addClass('hide');

    timeout = setTimeout(() => {
      $t.removeClass('show').removeClass('hide');
    }, 600);
  });

  $navigation.find('.header-has-sub-navigation a').on('click', (e) => {
    if (!isTablet()) return;
    const $t = $(e.currentTarget);

    accordionNavigation($t, $navigation, 'header');
  });

  $footerNavigation.find('.footer-has-sub-navigation a').on('click', (e) => {
    const $t = $(e.currentTarget);

    accordionNavigation($t, $footerNavigation, 'footer');
  });
}

function accordionNavigation($el, $nav, startClass) {
  if ($el.is('.active')) {
    $el.removeClass('active');
    $el.next().slideUp(300);
  } else {
    $nav.find(`.${startClass}-has-sub-navigation a`).removeClass('active');
    $nav.find(`.${startClass}-sub-navigation`).slideUp(300);

    $el.addClass('active');
    $el.next().slideDown(300);
  }
}
