import $ from 'jquery';

const animationDuration = 600;

export function popupInit() {
  $(document).on('click', '.popup-open', (e) => {
    e.preventDefault();
    let target;
    const href = $(e.currentTarget).attr('href');
    if (!href) {
      target = $(e.currentTarget).data('popup');
    } else {
      target = href;
    }

    if (!target) return;

    const $popup = $(target);

    let productId = $(e.currentTarget).data('product-id')
    $popup.find('form #feedback_product_id').val(productId)

    openPopup($popup);
  });

  $(document).on('click', '.open-popup-slide', (e) => {
    e.preventDefault();
    let target;
    const href = $(e.currentTarget).attr('href');
    if (!href) {
      target = $(e.currentTarget).data('popup');
    } else {
      target = href;
    }

    if (!target) return;

    const $popup = $(target);

    openPopup($popup);

    $popup
      .find('.example-popup-slider')
      .get(0)
      .swiper.slideTo($(e.currentTarget).data('slide'));
  });

  $(document).on('click', '.popup-close', (e) => {
    e.preventDefault();
    closePopup($(e.currentTarget).parents('.popup'));
  });

  $(document).on('click', '.popup-close-button', (e) => {
    e.preventDefault();
    closePopup($(e.currentTarget).parents('.popup'));
  });

  $(document).on('click', '.popup', (e) => {
    if (
      !$(e.target).closest('.popup-container').length &&
      $(e.currentTarget).is(':not(.popup-hard-close)')
    ) {
      closePopup($(e.currentTarget));
    }
  });
}

/**
 *
 * @param {jQuery} $popup
 */
export function openPopup($popup) {
  $('body').addClass('overflow-hidden');
  $popup.addClass('popup-show');
}

/**
 *
 * @param {jQuery} $parent
 */
export function closePopup($parent) {
  $('body').removeClass('overflow-hidden');
  $parent.addClass('popup-hide');

  setTimeout(() => {
    $parent.removeClass('popup-show').removeClass('popup-hide');
  }, animationDuration);
}

window.openPopup = openPopup;
window.closePopup = closePopup;

