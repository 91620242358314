export function catalog() {
  const $catalog = $('.home-catalog');
  if (!$catalog || $catalog.length === 0) return;

  const $nav = $('.home-catalog-navigation');

  $nav.find('.home-catalog-navigation-button').on('click', (e) => {
    const $t = $(e.currentTarget);
    const filter = $t.data('filter');

    if (!$t.is('.active')) {
      $nav.find('.active').removeClass('active');
      $t.addClass('active');
      $catalog.find('.home-catalog-item').removeClass('hide');
      if (filter !== 'all') {
        $catalog.find(`.home-catalog-item:not(.${filter})`).addClass('hide');
      }
    }
  });
}
