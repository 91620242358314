import { WHITE_MAP_STYLES } from './map-style';

export function locationMap() {
  const $map = $('#map-container');
  if (!$map || $map.length === 0) return;

  let mapContainer = document.getElementById('map-container');

  let map = new google.maps.Map(mapContainer, {
    zoom: parseInt(mapContainer.dataset.zoom),
    center: {
      lat: parseFloat(mapContainer.dataset.lat),
      lng: parseFloat(mapContainer.dataset.lng),
    },
    disableDefaultUI: true,
    styles: WHITE_MAP_STYLES,
  });

  let pin = new google.maps.Marker({
    position: new google.maps.LatLng(
      parseFloat(mapContainer.dataset.lat),
      parseFloat(mapContainer.dataset.lng)
    ),
    map: map,
    icon: mapContainer.dataset.icon,
    anchor: new google.maps.Point(-17, 0),
    scaledSize: new google.maps.Size(58, 44),
  });
}
