import SwiperCore, { Keyboard, Navigation } from 'swiper';
import Swiper from 'swiper';

export function newsItemSlider() {
  const $slider = $('.news-item-slider');
  if (!$slider || $slider.length === 0) return;

  SwiperCore.use([Navigation, Keyboard]);

  const swiper = new Swiper('.news-item-slider', {
    slidesPerView: 1,
    navigation: {
      nextEl: '.news-item-slider-next',
      prevEl: '.news-item-slider-prev',
    },
    keyboard: {
      enabled: true,
      onlyInViewport: true,
    },
  });
}
