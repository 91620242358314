export class FilterCore {
  showFiltered($forShow, showItems) {
    $forShow.each((i, el) => {
      const $t = $(el);
      if (i < showItems) {
        $t.removeClass('filter-hide');
      } else {
        $t.addClass('filter-hide');
      }
    });
  }

  checkLoadMore($forShow, $loadMore, showItems, showItemsStep) {
    if ($forShow.length <= showItemsStep) {
      $loadMore.addClass('hide');
    } else if (
      $forShow.length > showItemsStep &&
      $forShow.length <= showItems
    ) {
      $loadMore.addClass('hide');
    } else {
      $loadMore.removeClass('hide');
    }
  }
}
