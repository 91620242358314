export function productFilter() {
  const $catalogNavigation = $('.catalog-navigation');
  if (!$catalogNavigation || $catalogNavigation.length === 0) return;

  $('.catalog-navigation input:checkbox').on('change', function(e) {
    let checkboxList = $(e.currentTarget.closest('li'));
    if (checkboxList.hasClass('catalog-navigation-parent')) {
      let isChecked = e.currentTarget.checked
      checkboxList.find('.catalog-navigation-children li input:checkbox').each((i, elem) => {
        elem.checked = isChecked;
      })
    }

    let selected = [];
    $('.catalog-navigation input:checked').each(function() {
      selected.push(+$(this).data('id'));
    });

    $('.catalog-list-load-more').data('category_id', selected);
    $('.catalog-list-load-more').data('page', 2);

    $.ajax({
      url: '/catalog.js',
      data: { 'category_id': selected },
    });
  });

  $('.catalog-list-load-more').on('click', function() {
    $.ajax({
      url: '/catalog.js',
      data: $(this).data(),
    });

    $(this).data('page', $(this).data('page') + 1);
  });
}
