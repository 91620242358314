export function popupCallback() {
  const $button = $('.callback-popup-send');
  const $start = $('.callback-popup-form-start');
  const $end = $('.callback-popup-form-end');

  $button.on('click', (e) => {
    e.preventDefault();
    /*todo do something*/

    $start.addClass('hide');
    $end.removeClass('hide');
  });

  $('.callback-popup-button-close').on('click', () => {
    setTimeout(() => {
      $start.removeClass('hide');
      $end.addClass('hide');
    }, 600);
  });
}
