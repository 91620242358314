import { FilterCore } from '..';

export class NewsList extends FilterCore {
  $loadMore;
  showItems = 9;
  showItemsStep = 3;
  $item;

  init() {
    const $newsList = $('.news-list');
    if ($newsList && $newsList.length > 0) {
      this.$item = $newsList.find('.news-list-item');
      this.$loadMore = $('.news-list-load-more');
      this.addEventListener();
      this.showFiltered(this.$item, this.showItems);
    }
  }

  addEventListener() {
    this.$loadMore.on('click', () => {
      this.showItems += this.showItemsStep;
      this.showFiltered(this.$item, this.showItems);
      this.checkLoadMore(
        this.$item,
        this.$loadMore,
        this.showItems,
        this.showItemsStep
      );
    });
  }
}
