import Swiper, { Keyboard, Navigation } from 'swiper';
import SwiperCore from 'swiper';

export function projectSlider() {
  const $slider = $('.home-projects-slider');
  if (!$slider || $slider.length === 0) return;

  SwiperCore.use([Navigation, Keyboard]);

  const swiper = new Swiper('.home-projects-slider', {
    navigation: {
      nextEl: '.home-projects-slider-next',
      prevEl: '.home-projects-slider-prev',
    },
    keyboard: {
      enabled: true,
      onlyInViewport: true,
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
        spaceBetween: 0,
      },
      768: {
        slidesPerView: 'auto',
        spaceBetween: 30,
      },
    },
  });
}
