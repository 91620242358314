import {
  applicationsTabs,
  benefitAccordion,
  benefitTabs,
  catalog,
  counter,
  headInfoWindow,
  newsSliderMobile,
  projectSlider,
} from './components/home';
import { locationMap } from './modules/location-map';
import {
  burgerInit,
  cookies,
  inputMask,
  navigation,
  // popupCallback,
  popupInit,
  scrollTop,
  formCallback,
  initVideo,
} from './components';
import { CatalogFilter, catalogNavigation, productFilter } from './components/catalog';
import {
  ExampleFilter,
  examplePopupSlider,
  exampleSlider,
  newsItemSlider,
  NewsList,
  newsLoad,
  productSlider,
  projectsFilter,
  secondInfoBlockSlider,
  systemAccordion,
  systemComponentsTabs,
} from './components/second';

$(document).ready(init);

function init() {
  /*
   * Global
   * */

  inputMask();
  scrollTop();
  navigation();
  locationMap();
  popupInit();
  cookies();
  // popupCallback();
  burgerInit();
  // formCallback();
  initVideo();

  /*
   * Home
   * */

  headInfoWindow();
  benefitTabs();
  benefitAccordion();
  catalog();
  applicationsTabs();
  projectSlider();
  counter();
  newsSliderMobile();

  /*
   * Catalog
   * */

  catalogNavigation();
  // const catalogFilter = new CatalogFilter();
  // catalogFilter.init();
  productFilter();

  /*
   * Second
   * */

  exampleSlider();
  systemComponentsTabs();
  newsItemSlider();
  productSlider();
  examplePopupSlider();
  systemAccordion();

  secondInfoBlockSlider();

  // const newsList = new NewsList();
  // newsList.init();
  newsLoad();

  // const examplesFilter = new ExampleFilter();
  // examplesFilter.init();
  projectsFilter();
}
