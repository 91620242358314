export function benefitTabs() {
  const $benefits = $('.home-benefits');
  if (!$benefits || $benefits.length === 0) return;

  const $nav = $benefits.find('.home-benefits-tab-navigation');
  const $tabs = $benefits.find('.home-benefits-tab-content');
  const length = $tabs.find('.home-benefits-tab-pane').length;

  let active = 0;
  let timer = setInterval(() => {
    active = interval(
      active,
      length,
      $nav,
      $tabs,
      '.home-benefits-tab-navigation-button'
    );
  }, 15000);

  $nav.find('.home-benefits-tab-navigation-button').on('click', (e) => {
    const $t = $(e.currentTarget);
    const tab = $t.data('tab');
    if (!$t.is('.active')) {
      clearTabs($nav, $tabs);

      active = $t.index();
      clearInterval(timer);
      timer = setInterval(() => {
        active = interval(
          active,
          length,
          $nav,
          $tabs,
          '.home-benefits-tab-navigation-button'
        );
      }, 15000);

      $t.addClass('active');
      $(tab).addClass('show');
    }
  });
}

export function interval(active, length, $nav, $tabs, buttonClass) {
  if (active !== length) {
    const $button = $nav.find(buttonClass).eq(active);
    const tab = $button.data('tab');
    clearTabs($nav, $tabs);
    $button.addClass('active');
    $(tab).addClass('show');
    active++;
  } else {
    active = 0;
    clearTabs($nav, $tabs);
    const $button = $nav.find(buttonClass).eq(active);
    const tab = $button.data('tab');

    $button.addClass('active');
    $(tab).addClass('show');
    active++;
  }
  return active;
}

export function clearTabs($nav, $tabs) {
  $nav.find('.active').removeClass('active');
  $tabs.find('.show').removeClass('show');
}
