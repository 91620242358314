import SwiperCore, { Keyboard, Navigation } from 'swiper';
import Swiper from 'swiper';

export function secondInfoBlockSlider() {
  const $slider = $('.second-info-block-slider');
  if (!$slider || $slider.length === 0) return;

  SwiperCore.use([Navigation, Keyboard]);

  const swiper = new Swiper('.second-info-block-slider', {
    slidesPerView: 1,
    navigation: {
      nextEl: '.second-info-block-slider-next',
      prevEl: '.second-info-block-slider-prev',
    },
    keyboard: {
      enabled: true,
      onlyInViewport: true,
    },
  });
}
