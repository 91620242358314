import { FilterCore } from '..';

export class ExampleFilter extends FilterCore {
  $examplesNavigation;
  $button;
  $list;
  $forShow;
  $loadMore;
  filters = [];
  showItems = 4;
  showItemsStep = 4;

  init() {
    this.$examplesNavigation = $('.examples-navigation');
    if (this.$examplesNavigation && this.$examplesNavigation.length > 0) {
      this.$button = this.$examplesNavigation.find('.button');
      this.$list = $('.examples-list');
      this.$loadMore = $('.examples-list-load-more');
      this.addEventListeners();
      this.initLoadMore();

      this.filterData();
    }
  }

  addEventListeners() {
    this.$button.on('click', (e) => {
      const $t = $(e.currentTarget);
      const filter = $t.data('filter');
      if (!$t.is('.active')) {
        $t.addClass('active');
        this.filters.push(filter);
      } else {
        $t.removeClass('active');
        this.filters = this.filters.filter((f) => f !== filter);
      }
      this.filterData();
    });
  }

  initLoadMore() {
    this.$loadMore.find('.button').on('click', () => {
      this.showItems += this.showItemsStep;
      this.showFiltered(this.$forShow, this.showItems);
      this.checkLoadMore(
        this.$forShow,
        this.$loadMore,
        this.showItems,
        this.showItemsStep
      );
    });
  }

  filterData() {
    const $items = this.$list.find('.examples-list-item');

    if (this.filters.length === 0) {
      $items.addClass('filter-for-show');
    } else {
      $items.addClass('filter-hide').removeClass('filter-for-show');

      $items.each((i, el) => {
        const $t = $(el);
        let toShow = false;

        this.filters.map((filter) => {
          if ($t.data('filters').includes(filter)) toShow = true;
        });

        if (toShow) $t.addClass('filter-for-show');
      });
    }

    this.$forShow = this.$list.find('.filter-for-show');

    this.showFiltered(this.$forShow, this.showItems);
    this.checkLoadMore(
      this.$forShow,
      this.$loadMore,
      this.showItems,
      this.showItemsStep
    );
  }
}
