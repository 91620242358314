export function newsLoad() {
  const $newsLoadButton = $('.news-list-load-more');
  if (!$newsLoadButton || $newsLoadButton.length === 0) return;

  $newsLoadButton.on('click', function() {
    $.ajax({
      url: '/news.js',
      data: $(this).data(),
    });

    $(this).data('page', $(this).data('page') + 1);
  });
}
