import { FilterCore } from '..';

export class CatalogFilter extends FilterCore {
  $catalogNavigationCheckbox;
  $checkbox;
  $catalogList;
  $forShow;
  $loadMore;
  filters = [];
  showItems = 12;
  showItemsStep = 6;

  init() {
    this.$catalogNavigationCheckbox = $('.catalog-navigation-checkbox');
    if (
      this.$catalogNavigationCheckbox &&
      this.$catalogNavigationCheckbox.length > 0
    ) {
      this.$checkbox = this.$catalogNavigationCheckbox.find('input');
      this.$catalogList = $('.catalog-list');
      this.$loadMore = $('.catalog-list-load-more');
      this.addEventListeners();
      this.initLoadMore();

      this.filterData();
    }
  }

  initLoadMore() {
    this.$loadMore.find('.button').on('click', () => {
      this.showItems += this.showItemsStep;
      this.showFiltered(this.$forShow, this.showItems);
      this.checkLoadMore(
        this.$forShow,
        this.$loadMore,
        this.showItems,
        this.showItemsStep
      );
    });
  }

  addEventListeners() {
    this.$checkbox.on('change', (e) => {
      const $t = $(e.currentTarget);
      const dataParent = $t.data('parent');
      const dataParentName = $t.data('parent-name');
      const dataFilter = $t.data('filter');
      if (dataParent) this.selectParentCheckbox($t, dataParent);
      if (dataParentName) this.selectChildrenCheckbox($t, dataParentName);
      if (dataFilter) this.changeFilter($t, dataFilter);
      this.filterData();
    });
  }

  changeFilter($el, filter) {
    if ($el.prop('checked')) {
      this.filters.push(filter);
    } else {
      this.filters = this.filters.filter((f) => f !== filter);
    }
  }

  filterData() {
    const $items = this.$catalogList.find('.catalog-item');

    if (this.filters.length === 0) {
      $items.addClass('filter-for-show');
    } else {
      $items.addClass('filter-hide').removeClass('filter-for-show');

      $items.each((i, el) => {
        const $t = $(el);
        let toShow = false;

        this.filters.map((filter) => {
          if ($t.data('filters').includes(filter)) toShow = true;
        });

        if (toShow) $t.addClass('filter-for-show');
      });
    }

    this.$forShow = this.$catalogList.find('.filter-for-show');

    this.showFiltered(this.$forShow, this.showItems);
    this.checkLoadMore(
      this.$forShow,
      this.$loadMore,
      this.showItems,
      this.showItemsStep
    );
  }

  selectParentCheckbox($el, parent) {
    const $parent = $(`input[data-parent-name="${parent}"]`);
    if ($el.prop('checked')) {
      $el.parent().addClass('checked');
      $parent.parent().addClass('checked');
      $parent.prop('checked', true);
    } else {
      $el.parent().removeClass('checked');
      this.checkChildrenForParent($parent);
    }
  }

  selectChildrenCheckbox($el, parentName) {
    const checked = $el.prop('checked');
    const $children = $(`input[data-parent="${parentName}"]`);
    $children.prop('checked', checked);
    if (checked) {
      $el.parent().addClass('checked');
      $children.parent().addClass('checked');
    } else {
      $el.parent().removeClass('checked');
      $children.parent().removeClass('checked');
    }

    $children.each((i, el) => {
      this.changeFilter($(el), $(el).data('filter'));
    });
  }

  checkChildrenForParent($parent) {
    let hasCheckedChild = false;
    $parent
      .parents('.catalog-navigation-parent')
      .find('.catalog-navigation-children input[type=checkbox]')
      .each((i, el) => {
        if ($(el).prop('checked')) {
          hasCheckedChild = true;
        }
      });
    $parent.prop('checked', hasCheckedChild);
    if (!hasCheckedChild) $parent.parent().removeClass('checked');
  }
}
