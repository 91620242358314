import Cookies from 'js-cookie';

export function cookies() {
  const $button = $('.cookies-button');
  $button.on('click', () => {
    $('.cookies').fadeOut(300);
    /*todo do something*/
    Cookies.set('cookies', '1', { expires: 7, path: '/' })
  });

  if(!Cookies.get('cookies')){
    $('.cookies').fadeIn(300);
  }
}
