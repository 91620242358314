export function catalogNavigation() {
  const $catalogNavigation = $('.catalog-navigation');
  if (!$catalogNavigation || $catalogNavigation.length === 0) return;

  $catalogNavigation
    .find('.catalog-navigation-children-show')
    .on('click', (e) => {
      const $t = $(e.currentTarget);
      const $parent = $t.parent();
      if ($parent.is('.show-children')) {
        $parent.removeClass('show-children');
        $parent.find('.catalog-navigation-children').stop().slideUp(300);
      } else {
        $parent.addClass('show-children');
        $parent.find('.catalog-navigation-children').stop().slideDown(300);
      }
    });
}
