import { examplePopupSlider } from '.';

export function projectsFilter() {
  const $projectsLoadButton = $('.examples-list-load-more');
  if (!$projectsLoadButton || $projectsLoadButton.length === 0) return;

  $('.examples-navigation-button').on('click', function(e) {
    $(this).toggleClass('active');

    let selected = [];
    $('.examples-navigation-button.active').each(function() {
      $.merge(selected, $(this).data('id'));
    });

    $projectsLoadButton.data('category_id', selected);
    $projectsLoadButton.data('page', 2);

    $.ajax({
      url: '/projects.js',
      data: { 'category_id': selected },
    }).done(function() {
      examplePopupSlider();
    });
  });

  $projectsLoadButton.on('click', function() {
    $.ajax({
      url: '/projects.js',
      data: $(this).data(),
    }).done(function() {
      examplePopupSlider();
    });;

    $(this).data('page', $(this).data('page') + 1);
  });
}
