import Swiper, { Navigation } from 'swiper';
import SwiperCore from 'swiper';

export function newsSliderMobile() {
  const $slider = $('.home-news-slider');
  if (!$slider || $slider.length === 0) return;

  SwiperCore.use([Navigation]);

  const swiper = new Swiper('.home-news-slider', {
    slidesPerView: 1,
    navigation: {
      nextEl: '.home-news-slider-next',
      prevEl: '.home-news-slider-prev',
    },
  });
}
