export function headInfoWindow() {
  const $homeHead = $('.home-head');
  if (!$homeHead || $homeHead.length === 0) return;

  const $headPoint = $('.home-head-point');
  const $headNavButton = $('.home-head-navigation .button');
  const $headWindowClose = $('.home-head-info-window-close');

  $headPoint.on('click', (e) => {
    const $t = $(e.currentTarget);
    changeActive($t);
  });

  $headNavButton.on('click', (e) => {
    const $t = $(e.currentTarget);
    changeActive($t);
  });

  $headWindowClose.on('click', (e) => {
    const $t = $(e.currentTarget);
    changeActive($t.parent());
  });
}

function changeActive($t) {
  const section = $t.data('section');
  const $homeHead = $('.home-head');

  if ($t.is('.active')) {
    $homeHead.find(`[data-section="${section}"]`).removeClass('active');
  } else {
    $homeHead.find('.active').removeClass('active');
    $homeHead.find(`[data-section="${section}"]`).addClass('active');
  }
}
