import window from 'inputmask/lib/global/window';

export function scrollTop() {
  const $buttonScrollTop = $('.button-scroll-top');
  const $w = $(window);

  if ($w.scrollTop() === 0) {
    $buttonScrollTop.addClass('hide');
  }

  $w.on('scroll', () => {
    if ($w.scrollTop() === 0) {
      $buttonScrollTop.addClass('hide');
    } else {
      $buttonScrollTop.removeClass('hide');
    }
  });

  $buttonScrollTop.on('click', (e) => {
    e.preventDefault();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  });
}
