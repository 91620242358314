export function benefitAccordion() {
  const $benefits = $('.home-benefits');
  if (!$benefits || $benefits.length === 0) return;

  const $head = $benefits.find('.home-benefits-accordion-head');
  const $body = $benefits.find('.home-benefits-accordion-body');

  $head.on('click', (e) => {
    const $t = $(e.currentTarget);
    if ($t.is('.active')) {
      $t.removeClass('active');
      $t.next().slideUp(300);
    } else {
      $head.removeClass('active');
      $body.slideUp(300);
      $t.addClass('active');
      $t.next().slideDown(300);
    }
  });
}
