// Callback buttons
document.addEventListener('DOMContentLoaded', function() {

    window.onload = function() {
        setTimeout(function() {
            const block = document.querySelector('.callback-links-block');
            if (block) {
                block.style.display = 'block';
            }
        }, 1000);
    };

    const feedbackButton = document.querySelector('.feedback-button');
    const feedbackBlock = document.querySelector('.feedback-block');
    const callbackLinksBlock = document.querySelector('.callback-links-block');
    let timeoutId;

    function hideFeedbackBlock() {
        feedbackBlock.style.display = "none";
    }

    function setHideTimer() {
        timeoutId = setTimeout(hideFeedbackBlock, 1000);
    }

    feedbackButton.addEventListener("mouseenter", function() {
        clearTimeout(timeoutId);
        feedbackBlock.style.display = "flex";
    });

    feedbackButton.addEventListener("mouseleave", function() {
        setHideTimer();
    });

    feedbackBlock.addEventListener("mouseenter", function() {
        clearTimeout(timeoutId);
    });

    feedbackBlock.addEventListener("mouseleave", function() {
        setHideTimer();
    });

    hideFeedbackBlock();
});