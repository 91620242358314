import inViewport from 'in-viewport';

export function counter() {
  const $counters = $('.about-us-counters');
  if (!$counters || $counters.length === 0) return;

  const counters = document.querySelectorAll('.about-us-counter span');

  counters.forEach((el) => {
    inViewport(el, { debounce: 300 }, () => {
      const $el = $(el);

      $el.prop('Counter', 0).animate(
        {
          Counter: $el.data('to'),
        },
        {
          duration: 4000,
          easing: 'swing',
          step: function (now) {
            $el.text(Math.ceil(now));
          },
        }
      );
    });
  });
}
