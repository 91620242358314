import { openPopup, closePopup } from './popup';

export function formCallback() {
  $(document).on('submit', 'form', (e) => {
    e.preventDefault();

    var data = $(e.target).serializeArray();
    data.push({name: 'source', value: window.location.href});

    console.log(data);

    $.ajax({
      type: 'POST',
      url: '/mail/',
      data: $.param(data),
      dataType: 'json',
      success: function (response) {
        if (response['success']) {
          if($('#popup-callback').is(':visible')){
            closePopup($('#popup-callback'));
          }
          openPopup($('#popup-callback-done'));
      
          e.target.reset();
        }
      },
    });
  });
}
