import Inputmask from 'inputmask/lib/inputmask';

export function inputMask() {
  const selector = document.querySelectorAll('.phone-mask');

  selector.forEach((el) => {
    const mask = new Inputmask('+7(999)999-99-99');
    mask.mask(el);
  });
}
