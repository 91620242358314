import { interval } from './benefit-tabs';

export function applicationsTabs() {
  const $application = $('.home-applications');
  if (!$application || $application.length === 0) return;

  const $nav = $application.find('.home-applications-tab-navigation');
  const $tabs = $application.find('.home-applications-tab-container');
  const length = $tabs.find('.home-applications-tab-pane').length;

  let active = 0;
  let timer = setInterval(() => {
    active = interval(
      active,
      length,
      $nav,
      $tabs,
      '.home-applications-tab-navigation-button'
    );
  }, 7000);

  $nav.find('.home-applications-tab-navigation-button').on('click', (e) => {
    const $t = $(e.currentTarget);
    const tab = $t.data('tab');
    if (!$t.is('.active')) {
      $nav.find('.active').removeClass('active');
      $t.addClass('active');
      active = $t.index();

      clearInterval(timer);
      timer = setInterval(() => {
        active = interval(
          active,
          length,
          $nav,
          $tabs,
          '.home-applications-tab-navigation-button'
        );
      }, 5000);

      $tabs.find('.show').removeClass('show');
      $(tab).addClass('show');
    }
  });
}
